import React from "react";

const Navbar = () => {
    return(
        <nav className="bg-teal-700 p-4">
            <div className="container mx-auto flex justify-between items-center">
                <h1 className="text-white text-2xl font-bold ">Daiva</h1>
                <div>
                    <a href="#about" className="text-gray-300 hover:text-white mx-4 transition duration-300 font-bold text-2xl">About</a>
                    <a href="#project" className="text-gray-300 hover:text-white mx-4 transition duration-300 font-bold text-2xl">Project</a>
                    <a href="#contact" className="text-gray-300 hover:text-white mx-4 transition duration-300 font-bold text-2xl">Contact</a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar