import React from "react";
import Cover from '../asset/background.jpeg';

const Hero = () => {
    return(
        <section className="bg-cover bg-center h-screen text-white" 
        style={{ backgroundImage: `url(${Cover})` }}
        >
            <div className="bg-black bg-opacity-50 h-full flex flex-col justify-center items-center text-center p-6">
                <h2 className="text-5xl font-bold mb-4">Hello, I'm Daiva Kauthar Rafif</h2>
                <p className="text-5xl mb-8 font-bold">KIDI Intern</p>
                <a href="#contact" className="bg-teal-700 hover:bg-teal-500 text-white font-semibold text-2xl py-4 px-6 rounded-full shadow-lg transition duration-300">
                    Contact
                </a>
            </div>

        </section>
    )
}

export default Hero;