import React from "react";
import Weather from '../asset/weather.png';
import GIF from '../asset/GIFgenerator.png';
import ProfileD from '../asset/desainprofile.png';
import MusicWeb from '../asset/desainwebmusic.png';

const Project = () => {
    return (
        <section id="project" className="py-20 bg-cyan-950">
            <div className="flex justify-center">
                <hr className="pb-16 border-t-8 w-11/12" />
            </div>
            <div className="container mx-auto text-center">
                <h2 className="text-2xl font-bold mb-8 text-white">My Project</h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="bg-teal-700 p-4 rounded-xl w-2/4 mx-auto mr-9 mb-11"> {/* Tambahkan w-3/4 dan mx-auto */}
                        <img src={Weather} alt="WeatherApp" className="w-full h-80 object-cover mb-4 rounded-xl" />
                        <h3 className="text-2xl font-bold mb-2 text-white">WeatherApp</h3>
                        <p className="text-white">Project untuk mengecek cuaca suatu wilayah</p>
                    </div>
                    <div className="bg-teal-700 p-4 rounded-xl w-2/4 mx-auto ml-9 mb-11"> {/* Tambahkan w-3/4 dan mx-auto */}
                        <img src={GIF} alt="GIFGenerator" className="w-full h-80 object-cover mb-4 rounded-xl" />
                        <h3 className="text-2xl font-bold mb-2 text-white">GIF Generator</h3>
                        <p className="text-white">Project untuk generator GIF secara random</p>
                    </div>
                    <div className="bg-teal-700 p-4 rounded-xl w-2/4 mx-auto mr-9"> {/* Tambahkan w-3/4 dan mx-auto */}
                        <img src={ProfileD} alt="Profile Design" className="w-full h-80 object-cover mb-4 rounded-xl" />
                        <h3 className="text-2xl font-bold mb-2 text-white">Desain Profile</h3>
                        <p className="text-white">Desain untuk tampilan sebuah profile</p>
                    </div>
                    <div className="bg-teal-700 p-4 rounded-xl w-2/4 mx-auto ml-9"> {/* Tambahkan w-3/4 dan mx-auto */}
                        <img src={MusicWeb} alt="Music Web Design" className="w-full h-80 object-cover mb-4 rounded-xl" />
                        <h3 className="text-2xl font-bold mb-2 text-white">Desain Music Web</h3>
                        <p className="text-white">Desain untuk iklan music web</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Project;
