import React from "react";

const Contact = () => {
    return(
        <section id="contact" className="py-20 bg-cyan-950">
            <div className="flex justify-center">
            <hr className="pb-16 border-t-8 w-11/12"></hr>
            </div>
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-4xl font-bold text-white">Contact Me</h2>
                <p className="text-xl text-white">Feel Free to leave me a message</p>
                <p className="text-xl mb-4 text-white">daivakautharr@gmail.com</p>
                <a href="mailto:daivakautharr@gmail.com" className="bg-teal-600 hover:bg-teal-400 text-white font-bold py-2 px-6 rounded shadow-md transition duration-300">
                    Contact
                </a>
            </div>
            <div className="flex justify-center">
            <hr className=" border-t-8 w-11/12 mt-16"></hr>
            </div>
        </section>
    )
}

export default Contact;