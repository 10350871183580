import React from "react";
import Profile from '../asset/profile.jpeg';
import reactp from '../asset/ReactJs.png';
import UIUX from '../asset/UIUX.png';
import node from '../asset/node.png';

const About = () => {
    return (
        <section id="about" className="py-16 bg-cyan-950">
            <div className="flex justify-center">
                <hr className="pb-16 border-t-8 w-11/12"></hr>
            </div>
            <div className="container mx-auto text-center px-6">
                <h2 className="text-4xl font-bold mb-8 text-white">
                    Tentang Saya
                </h2>

                <div className="flex flex-col items-center mb-12">
                    <img src={`${Profile}`} alt="Profile Picture" className="w-32 h-32 rounded-full object-cover mb-4 shadow-lg" />
                    <p className="text-lg text-white mb-2">KIDI Intern</p>
                    <p className="text-xl text-white font-semibold">I’m an software engineer intern in KIDI Telkom edoucorse</p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="flex flex-col items-center max-w-xs mx-auto bg-teal-700 p-6 rounded-lg shadow-lg hover:bg-teal-500 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-white">ReactJs</h3>
                        <img src={reactp} alt="ReactJs" className="w-20 h-20 object-cover mb-4 rounded-xl" />
                        <p className="text-white mb-4">
                            React JS adalah library JavaScript yang biasa digunakan saat membangun UI suatu website atau aplikasi web.
                        </p>
                    </div>
                    <div className="flex flex-col items-center max-w-xs mx-auto bg-teal-700 p-6 rounded-lg shadow-lg hover:bg-teal-500 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-white">UI/UX</h3>
                        <img src={UIUX} alt="UI/UX" className="w-20 h-20 object-cover mb-4 rounded-xl"/>
                        <p className="text-white mb-4">
                            UI UX adalah kepanjangan dari User Interface (UI) dan User Experience (UX).
                        </p>
                    </div>
                    <div className="flex flex-col items-center max-w-xs mx-auto bg-teal-700 p-6 rounded-lg shadow-lg hover:bg-teal-500 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-white">Node.js</h3>
                        <img src={node} alt="Node" className="w-full h-20 object-contain mb-4 rounded-xl"/>
                        <p className="text-white mb-4">
                            Node.js adalah runtime environment lintas platform single-thread yang dibangun berdasarkan engine JavaScript V8 Chrome.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
